<template>
    <v-expansion-panel-content>
        <v-row>
            <v-col cols="6" sm="3">
                <v-select
                    label="Groupe ex. DPS"
                    :value="dps"
                    :items="[1,2,3,4]"
                    :loading="loading"
                    :readonly="!editing"
                    @change="$emit('update:dps', $event)"
                />
            </v-col>
            <v-col cols="6" sm="3">
                <v-select
                    label="Groupe ex. APR"
                    :value="apr"
                    :items="['A','B','C']"
                    :loading="loading"
                    :readonly="!editing"
                    @change="$emit('update:apr', $event)"
                />
            </v-col>
        </v-row>
    </v-expansion-panel-content>
</template>

<script>
export default {
    name: 'mUserProfilePanelTraining',
    props: {
        dps: { type: [String, Number], default: null },
        apr: { type: [String, Number], default: null },
        loading: {type: [Boolean, String], default: 'accent'}, 
        editing: Boolean,
    },
    data: () => ({
    }),
    methods: {
    }
}
</script>