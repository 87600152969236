<template>
    <v-btn
        v-if="hasAccount"
        :color="button[btnStatus].color"
        :loading="sendLoading"
        :disabled="sendLoading"
        @click="updateClaims"
        @click.prevent
    >
        {{ button[btnStatus].text }}
        <v-icon right>{{ button[btnStatus].icon }}</v-icon>
    </v-btn>
</template>

<script>
import { getApp } from 'firebase/app';
import { getFunctions, httpsCallable } from 'firebase/functions';
const functions = getFunctions(getApp(), 'europe-west1');
const setClaims = httpsCallable(functions, 'user-setClaims');

export default {
    name: 'mUserProfileClaimsSaveClaims',
    props: {
        value: Object,
        hasAccount: {type: Boolean, default: true},
    },
    // props: ['value'],
    data: () => ({
        sendLoading: false,
        btnStatus: 'default',
        button: {
            default: { color: 'primary', icon: 'mdi-content-save', text: 'Enregistrer' },
            success: { color: 'success', icon: 'mdi-check', text: 'Enregistré' },
            failure: { color: 'error', icon: 'mdi-alert', text: 'Echec' },
        }
    }),
    methods: {
        updateClaims() {
            this.sendLoading = true;
            const uid = this.$route.params.id;
            setClaims({ uid: uid, claims: this.value }).then(res => {
                this.btnStatus = 'success';
                console.log('New claims:', res.data);
            }).catch(error => {
                this.btnStatus = 'failure';
                this.$store.commit('setError', { type: 'firebase-functions', code: error.message, message: error.details });
            }).finally(() => {
                this.sendLoading = false;
                setTimeout(() => {this.btnStatus = 'default'}, 3000);
            });
        },
    }
}
</script>

<style>

</style>