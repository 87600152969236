<template>
    <div class="text-right my-3">
        <v-btn
            :color="button[btnStatus].color"
            :loading="sendLoading"
            :disabled="sendLoading || disabled"
            @click="updateProfile"
            @click.prevent
        >
            {{ button[btnStatus].text }}
            <v-icon right>{{ button[btnStatus].icon }}</v-icon>
        </v-btn>
    </div>
</template>

<script>
import { doc, getFirestore, updateDoc } from 'firebase/firestore';
const db = getFirestore();

export default {
    name: 'mUserProfileSavePanelBtn',
    props: {
        newData: {type: Object, required: true},
        disabled: {type: Boolean, default: false},
    },
    data: () => ({
        sendLoading: false,
        btnStatus: 'default',
        button: {
            default: { color: 'primary', icon: 'mdi-content-save', text: 'Enregistrer' },
            success: { color: 'success', icon: 'mdi-check', text: 'Enregistré' },
            failure: { color: 'error', icon: 'mdi-alert', text: 'Echec' },
        }
    }),
    methods: {
        updateProfile() {
            this.sendLoading = true;
            const uid = this.$route.params.id;
            const newProfile = this.newData.getProfileInfo();

            updateDoc(doc(db, 'users', uid), newProfile).then(() => {
                this.btnStatus = 'success';
                this.$emit('saved');
            }).catch(error => {
                this.btnStatus = 'failure';
                this.$store.commit('setError', { type: 'firebase-firestore', code: error.code, message: error.message });
            }).finally(() => {
                this.sendLoading = false;
                setTimeout(() => {this.btnStatus = 'default'}, 3000);
            });
        },
    }
}
</script>

<style>

</style>