<template>
    <div>
        <span class="text-h6">Droits globaux</span>
        <br>
        <span class="text-caption">
            Les personnes avec des droits globaux peuvent voir tous les modules, en plus des droits décrits sous les boutons.
            <br>
            Ces utilisateurs présentent également des avantages du côté serveur, il faut donc restreindre leur utilisation au minimum !
            <ul>
                <li>
                    Super admin
                    <ul>
                        <li>Accès à tous les modules en lecture/écriture</li>
                        <li>Gérer les droits d'accès (incl. droits globaux)</li>
                    </ul>
                </li>
                <li>
                    Super modérateur
                    <ul>
                        <li>Accès à tous les modules en lecture</li>
                        <li>Gérer les droits d'accès (excl. drois globaux)</li>
                    </ul>
                </li>
            </ul>
        </span>
        <v-row>
            <v-col cols="12" sm="4" lg="3">
                <m-claims-checkbox
                    :value="superAdmin"
                    label="Super admin"
                    hint="Peut tout faire, même le bordel"
                    @change="$emit('update:superAdmin', $event)"
                />
            </v-col>
            <v-col cols="12" sm="4" lg="3">
                <m-claims-checkbox
                    :value="superMod"
                    label="Super modérateur"
                    hint="Peut tout voir mais pas toucher"
                    @change="$emit('update:superMod', $event)"
                />
            </v-col>
        </v-row>
    </div>
</template>

<script>
import mClaimsCheckbox from './checkbox.vue';

export default {
    name: 'mUserProfileClaimsSuper',
    components: {
        mClaimsCheckbox
    },
    props: {
        superMod: Boolean, 
        superAdmin: Boolean,
    },
}
</script>

<style>

</style>