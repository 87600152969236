export * from './gear';
export * from './places';

export const NAME_TITLES = {
    mrs: { value: 'mrs', name: 'Mme.', long: 'Madame' },
    mr: { value: 'mr', name: 'M.', long: 'Monsieur' },
    ms: { value: 'ms', name: 'Mlle.', long: 'Mademoiselle' },
}

export const RANKS = {
    asp: { id: 'asp', value: 0, type: 'base', short: 'Asp', med: 'Asp', long: 'Aspirant' },
    sap: { id: 'sap', value: 1, type: 'base', short: 'Sap', med: 'Sap', long: 'Sapeur' },
    app: { id: 'app', value: 2, type: 'base', short: 'App', med: 'App', long: 'Appointé' },
    appc: { id: 'appc', value: 3, type: 'base', short: 'App c', med: 'App chef', long: 'Appointé-chef' },
    cpl: { id: 'cpl', value: 4, type: 'soff', short: 'Cpl', med: 'Cpl', long: 'Caporal' },
    sgt: { id: 'sgt', value: 5, type: 'soff', short: 'Sgt', med: 'Sgt', long: 'Sergent' },
    sgtc: { id: 'sgtc', value: 6, type: 'soff', short: 'Sgt c', med: 'Sgt chef', long: 'Sergent-chef' },
    sgtm: { id: 'sgtm', value: 7, type: 'soffsup', short: 'Sgt m', med: 'Sgtm', long: 'Sergent-major' },
    four: { id: 'four', value: 8, type: 'soffsup', short: 'Four', med: 'Four', long: 'Fourrier' },
    sgtmc: { id: 'sgtmc', value: 9, type: 'soffsup', short: 'Sgt mc', med: 'Sgtm chef', long: 'Sergent-major chef' },
    adj: { id: 'adj', value: 10, type: 'soffsup', short: 'Adj', med: 'Adj', long: 'Adjudant' },
    adjc: { id: 'adjc', value: 11, type: 'soffsup', short: 'Adj c', med: 'Adj chef', long: 'Adjudant-chef' },
    lt: { id: 'lt', value: 12, type: 'off', short: 'Lt', med: 'Lt', long: 'Lieutenant' },
    plt: { id: 'plt', value: 13, type: 'off', short: 'Plt', med: 'Plt', long: 'Premier-lieutenant' },
    cap: { id: 'cap', value: 14, type: 'off', short: 'Cap', med: 'Cap', long: 'Capitaine' },
    maj: { id: 'maj', value: 15, type: 'offsup', short: 'Maj', med: 'Maj', long: 'Major' },
    ltcol: { id: 'ltcol', value: 16, type: 'offsup', short: 'Lt col', med: 'Lt col', long: 'Lieutenant-colonel' },
    col: { id: 'col', value: 17, type: 'offsup', short: 'Col', med: 'Col', long: 'Colonel' },
}

/**
 * Sort function based on Array.prototype.sort()
 * @param {string} a id of first rank
 * @param {string} b id of second rank
 * @returns {number} same as Array.prototype.sort(a, b)
 * @example
 * // Here a and b are already rank id's
 * arr.sort((a, b) => SORT_RANKS(a, b));
 * @example
 * // If a and b are UserProfiles
 * arr.sort((a, b) => SORT_RANKS(a.rank, b.rank));
 */
export function SORT_RANKS(a, b) {
    const rankA = RANKS[a] || 0;
    const rankB = RANKS[b] || 0;
    return rankA.value - rankB.value;
}

export const ROLES = {
    dapExec: { value: 'dapExec', long: 'Cadre DAP', short: 'Cadre DAP' },
    comprom: { value: 'comprom', long: 'ComProm', short: 'ComProm' },
    em: { value: 'em', long: 'Etat-major', short: 'EM' },
    inframat: { value: 'inframat', long: 'Equipe matériel', short: 'Eq. mat' },
    teachClimb: { value: 'teachClimb', long: 'Formateur antichute', short: 'From. antichute' },
    teachApr: { value: 'teachApr', long: 'Formateur APR', short: 'Form. APR' },
    teachDrive: { value: 'teachDrive', long: 'Formateur chauffeur', short: 'Form. chauf.' },
    teachDps: { value: 'teachDps', long: 'Formateur DPS', short: 'Form. DPS' },
    teachJsp: { value: 'teachJsp', long: 'Moniteur JSP', short: 'Monit. JSP' },
    scbaTech: { value: 'scbaTech', long: 'Préposé APR', short: 'Prép. APR' },
}