<template>
    <div>
        <span class="text-h6">Module équipement</span>
        <br>
        <span class="text-caption">
            Chaque niveau permet de voir la page &laquo; équipement &raquo;
        </span>
        <v-row>
            <v-col cols="12" sm="4" lg="3">
                <m-claims-checkbox
                    :value="gearAdmin"
                    label="Administrateur"
                    hint="Créer, modifier et supprimer l'équipement"
                    @change="$emit('update:gearAdmin', $event)"
                />
            </v-col>
            <v-col cols="12" sm="4" lg="3">
                <m-claims-checkbox
                    :value="gearMod"
                    label="Modérateur"
                    hint="Créer et modifier l'équipement"
                    @change="$emit('update:gearMod', $event)"
                />
            </v-col>
            <v-col cols="12" sm="4" lg="3">
                <m-claims-checkbox
                    :value="gearUser"
                    label="Utilisateur"
                    hint="Voir les items anciens"
                    @change="$emit('update:gearUser', $event)"
                />
            </v-col>
        </v-row>
    </div>
</template>

<script>
import mClaimsCheckbox from './checkbox.vue';
export default {
    name: 'mUserProfileClaimsGear',
    components: {
        mClaimsCheckbox
    },
    props: {
        gearAdmin: Boolean,
        gearMod: Boolean,
        gearUser: Boolean,
    },
}
</script>

<style>

</style>