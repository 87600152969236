<template>
    <v-container>
        <v-row class="mb-1">
            <v-col>
                <v-btn color="secondary"
                    :to="{name: 'Users'}"
                    small
                >
                    <v-icon :left="big">mdi-format-list-bulleted-square</v-icon>
                    <span v-if="big">Liste</span>
                </v-btn>
            </v-col>

            <v-col cols="auto">
                <v-btn
                    v-if="canEdit"
                    :disabled="!exists"
                    :color="editing ? 'warning' : 'secondary'"
                    small
                    @click="toggleEditing"
                >
                    <v-icon :left="big">mdi-pencil</v-icon>
                    <span v-if="big">{{ editing ? "Modifiable" : "Modifier" }}</span>
                </v-btn>
            </v-col>
        </v-row>

        <v-skeleton-loader
            v-if="loading"
            type="list-item-avatar-three-line"
        />

        <v-alert v-else-if="!exists" type="warning" prominent>
            L'utilisateur que vous cherchez n'existe pas
        </v-alert>
        
        <m-user-profile-basic-info
            v-else
            :profile="user"
            :nameFirst.sync="user.name.first"
            :nameLast.sync="user.name.last"
            :loading="loading"
            :editing="editing"
        />
        
        <m-user-profile-panels
            :user.sync="user"
            :loading="loading"
            :editing="editing"
            @update="edited = true"
        />

        <m-user-profile-save-panel-btn
            v-if="canEdit"
            :newData="user"
            :disabled="!editing"
            @saved="editing = false"
        />

        <m-user-profile-claims v-if="exists" :hasAccount="user.hasAccount"/>
    </v-container>
</template>

<script>
import mUserProfileBasicInfo from '../components/userProfile/basicInfo.vue';
import mUserProfilePanels from '../components/userProfile/profilePanels.vue';
import mUserProfileClaims from '../components/userProfile/claims.vue';
import mUserProfileSavePanelBtn from '../components/userProfile/savePanels.vue';
import { doc, getFirestore, onSnapshot } from 'firebase/firestore';
const db = getFirestore();
let dbUnsub;

export default {
    name: 'mUserProfile',
    components: {
        mUserProfileBasicInfo,
        mUserProfilePanels,
        mUserProfileClaims,
        mUserProfileSavePanelBtn
    },
    data: () => ({
        user: new window.UserProfile(),
        loading: 'accent',
        claimsLoading: true,
        editing: false,
        edited: false,
        exists: false,
    }),
    computed: {
        canEdit() {
            return this.$store.getters.canAccess('users_admin', 'users_mod');
        },
        big() {
            return !this.$vuetify.breakpoint.xsOnly;
        },
    },
    watch: {
        $route(to) {
            this.user = new window.UserProfile();
            this.loading = 'accent';
            this.claimsLoading = true;
            this.editing = false;
            this.edited = false;
            this.fetchUser(to.params.id);
        }
    },
    created() {
        this.fetchUser(this.$route.params.id);
    },
    destroyed() {
        if (dbUnsub) dbUnsub();
    },
    methods: {
        fetchUser(uid) {
            if (dbUnsub) dbUnsub();
            this.loading = 'accent';

            dbUnsub = onSnapshot(doc(db, 'users', uid), doc => {
                if (doc.exists()) {
                    let data = doc.data();
                    data.uid = doc.id;
                    this.user = new window.UserProfile(data);
                    this.exists = true;
                }
                else {
                    this.exists = false;
                }
                this.loading = false;
            });
        },
        toggleEditing() {
            if (this.editing) {
                const res = window.confirm("Etes-vous sûr de vouloir annuler ? Toute modification sera perdue. \n La page sera rafraîchie.");
                if (res) {
                    this.fetchUser(this.user.uid)
                    this.editing = false;
                }
            }
            else {
                this.editing = true;
            }
        }
    },
}
</script>

<style>

</style>