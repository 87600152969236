<template>
    <v-expansion-panel-content>
        <v-row>
            <v-col cols="12" sm="4">
                <v-select
                    label="Grade"
                    :value="rank"
                    :items="getRanks"
                    item-text="long"
                    item-value="id"
                    :loading="loading"
                    :readonly="!editing"
                    @change="$emit('update:rank', $event)"
                />
            </v-col>
            <v-col cols="12" sm="4">
                <v-select
                    label="Détachement"
                    :value="section"
                    :items="['DPS', 'DAP']"
                    :loading="loading"
                    :readonly="!editing"
                    @change="$emit('update:section', $event)"
                />
            </v-col>
            <v-col cols="12" sm="4">
                <v-select
                    label="Organe d'intervetion"
                    :value="station"
                    :items="getStations"
                    item-value="value"
                    item-text="name"
                    :loading="loading"
                    :readonly="!editing"
                    @change="$emit('update:station', $event)"
                />
            </v-col>
            <v-col cols="6" sm="3">
                <v-checkbox
                    label="Porteur APR"
                    :input-value="scba"
                    :readonly="!editing"
                    @change="$emit('update:scba', $event)"
                />
            </v-col>
            <v-col cols="6" sm="3">
                <v-checkbox
                    label="Permis C1"
                    :input-value="c1Driver"
                    :readonly="!editing"
                    @change="$emit('update:c1Driver', $event)"
                />
            </v-col>
            <v-col cols="6" sm="3">
                <v-checkbox
                    label="Antichute"
                    :input-value="climber"
                    :readonly="!editing"
                    @change="$emit('update:climber', $event)"
                />
            </v-col>
            <v-col cols="6" sm="3">
                <v-checkbox
                    label="Chef d'engin"
                    :input-value="cde"
                    :readonly="!editing"
                    @change="$emit('update:cde', $event)"
                />
            </v-col>
            <v-col cols="6" sm="3">
                <v-checkbox
                    label="Chef d'intervention"
                    :input-value="ci"
                    :readonly="!editing"
                    @change="$emit('update:ci', $event)"
                />
            </v-col>
        </v-row>
    </v-expansion-panel-content>
</template>

<script>
import { RANKS } from '@/constants';
import { STATIONS } from '@/constants/places';

export default {
    name: 'mUserProfilePanelOperational',
    props: {
        rank: {type: String, default: ''},
        section: {type: String, default: ''},
        station: {type: String, default: ''},
        scba: {type: Boolean},
        c1Driver: {type: Boolean},
        cde: {type: Boolean},
        climber: {type: Boolean},
        ci: {type: Boolean},
        loading: {type: [Boolean, String], default: 'accent'}, 
        editing: Boolean,
    },
    data: () => ({
        mProfile: {},
    }),
    computed: {
        getRanks() {
            return Object.values(RANKS);
        },
        getStations() {
            return Object.values(STATIONS);
        }
    },
    created() {
        this.mProfile = this.profile;
    },
}
</script>