<template>
    <v-card
        v-if="canSee"
        class="mt-12"
    >
        <v-card-title>
            Droits d'accès
            <v-spacer />
            <v-btn
                color="secondary"
                small
                :disabled="!hasAccount"
                @click="fetchClaims($route.params.id)"
            >
                <v-icon :left="big">mdi-refresh</v-icon>
                {{ big ? "Rafraichir" : "" }}
            </v-btn>
        </v-card-title>
        <v-card-text>
            <v-progress-linear
                v-if="loading"
                color="accent"
                indeterminate
            />
            <v-alert
                v-else-if="hasAccount === false"
                type="warning"
                text outlined
            >
                Cet utilisateur n'a pas de compte.
                <br>
                Contactez votre responsable informatique si cela est une erreur.
            </v-alert>
            <v-alert
                v-else-if="errorOccured"
                type="error"
                prominent
            >
                Une erreur est survenue lors de la recherche des doits d'accès.
                <br>
                Contactez votre responsable informatique.
            </v-alert>

            <!-- Claim panals -->
            <div v-else>
                <m-user-profile-claims-super
                    :super-admin.sync="claims.superAdmin"
                    :super-mod.sync="claims.superMod"
                />
                <v-divider class="my-5" />
                <m-user-profile-claims-users 
                    :users-admin.sync="claims.users_admin"
                    :users-mod.sync="claims.users_mod"
                />
                <v-divider class="my-5" />
                <m-user-profile-claims-keytubes 
                    :keytubes-admin.sync="claims.keytubes_admin"
                    :keytubes-mod.sync="claims.keytubes_mod"
                    :keytubes-user.sync="claims.keytubes_user"
                />
                <v-divider class="my-5" />
                <m-user-profile-claims-gear 
                    :gear-admin.sync="claims.gear_admin"
                    :gear-mod.sync="claims.gear_mod"
                    :gear-user.sync="claims.gear_user"
                />
            </div>
            
        </v-card-text>
        <v-card-actions>
            <v-spacer />
            <m-user-profile-claims-save-claims :value="claims" v-if="!loading" :hasAccount="hasAccount" />
        </v-card-actions>
    </v-card>
</template>

<script>
import mUserProfileClaimsSuper from './claimSettings/super.vue';
import mUserProfileClaimsUsers from './claimSettings/users.vue';
import mUserProfileClaimsKeytubes from './claimSettings/keytubes.vue';
import mUserProfileClaimsGear from './claimSettings/gear.vue';
import mUserProfileClaimsSaveClaims from './claimSettings/saveClaims.vue';

import { getApp } from 'firebase/app';
import { getFunctions, httpsCallable } from 'firebase/functions';
const functions = getFunctions(getApp(), 'europe-west1');
const getClaims = httpsCallable(functions, 'user-getClaims')

export default {
    name: 'mUserProfileClaims',
    components: {
        mUserProfileClaimsSuper,
        mUserProfileClaimsUsers,
        mUserProfileClaimsKeytubes,
        mUserProfileClaimsGear,
        mUserProfileClaimsSaveClaims,
    },
    props: {
        hasAccount: {type: Boolean, default: false},
    },
    data() { return {
        claims: {},
        loading: false,
        errorOccured: false,
    }},
    computed: {
        canSee() {
            return this.$store.getters.canAccess();
        },
        big() {
            return !this.$vuetify.breakpoint.xsOnly;
        },
    },
    watch: {
        $route(to) {
            this.fetchClaims(to.params.id);
            this.errorOccured = false;
        }
    },
    mounted() {
        this.fetchClaims(this.$route.params.id);
    },
    methods: {
        fetchClaims(uid) {
            if (this.canSee && this.hasAccount) {
                this.loading = true;
                this.claims = {};
                getClaims({uid: uid}).then(result => {
                    // console.log('Claims fethced!');
                    this.claims = result.data || {};
                }).catch(error => {
                    this.$store.commit('setError', { type: 'functions', code: error.code, message: error.message });
                    this.errorOccured = true;
                }).finally(() => {
                    this.loading = false;
                });
            }
        },
    },
}
</script>