<template>
    <v-expansion-panel-content>
        <v-row>
            <v-col cols="12">
                <v-text-field
                    label="Titre"
                    :value="title"
                    :loading="loading"
                    :readonly="!editing"
                    @input="$emit('update:title', $event)"
                />
            </v-col>
            <v-col
                v-for="role in roles" :key="role.long"
                cols="12" sm="4" lg="3"
            >
                <v-checkbox
                    :label="role.long"
                    :input-value="$props[role.value]"
                    :readonly="!editing"
                    hide-details
                    @change="$emit(`update:${role.value}`, $event)"
                />
            </v-col>
        </v-row>
    </v-expansion-panel-content>
</template>

<script>
import { ROLES } from '@/constants';

export default {
    name: 'mUserProfilePanelRoles',
    props: {
        loading: {type: [Boolean, String], default: 'accent'}, 
        editing: Boolean,
        title: {type: String, default: ''},
        em: {type: Boolean},
        inframat: {type: Boolean},
        comprom: {type: Boolean},
        scbaTech: {type: Boolean},
        teachDps: {type: Boolean},
        teachApr: {type: Boolean},
        teachDrive: {type: Boolean},
        teachRopes: {type: Boolean},
        teachJsp: {type: Boolean},
        dapExec: {type: Boolean},
    },
    data: () => ({
        mProfile: {},
        roles: Object.values(ROLES),
    }),
    created() {
        this.mProfile = this.profile;
    },
}
</script>