<template>
    <div>
        <span class="text-h6">Gestion des utilisateurs</span>
        <v-row>
            <v-col cols="12" sm="4" lg="3">
                <m-claims-checkbox
                    :value="usersAdmin"
                    label="Administrateur"
                    hint="Créer, modifier et supprimer des utilisateurs"
                    @change="$emit('update:usersAdmin', $event)"
                />
            </v-col>
            <v-col cols="12" sm="4" lg="3">
                <m-claims-checkbox
                    :value="usersMod"
                    label="Modérateur"
                    hint="Modifier les utilisateurs"
                    @change="$emit('update:usersMod', $event)"
                />
            </v-col>
        </v-row>
    </div>
</template>

<script>
import mClaimsCheckbox from './checkbox.vue';
export default {
    name: 'mUserProfileClaimsUsers',
    components: {
        mClaimsCheckbox
    },
    props: {
        usersAdmin: Boolean,
        usersMod: Boolean,
    },
}
</script>

<style>

</style>