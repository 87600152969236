<template>
    <v-card class="mb-5">
        <v-card-text>
            <v-row>
                <v-col cols="12" sm="auto">
                    <v-avatar
                        color="accent"
                        size="124">
                        <v-progress-circular
                            v-if="loading"
                            color="white"
                            indeterminate/>
                        <span v-else class="text-h3 white--text">
                            {{ profile.getInitials() }}
                        </span>
                    </v-avatar>
                </v-col>

                <v-col>
                    <v-text-field
                        label="Nom"
                        :value="nameLast"
                        :loading="loading"
                        :readonly="!editing"
                        @input="$emit('update:nameLast', $event)"
                    />
                    <v-text-field
                        label="Prénom"
                        :value="nameFirst"
                        :loading="loading"
                        :readonly="!editing"
                        @input="$emit('update:nameFirst', $event)"
                    />
                    <v-chip v-if="!!profile.status" v-text="statuses[profile.status]" class="mr-1"/>
                    <v-chip v-if="!!profile.section" v-text="profile.section"/>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    name: 'mUserProfileBasicInfo',
    props: {
        profile: {type: Object, required: true},
        nameFirst: {type: String, default: ''},
        nameLast: {type: String, default: ''},
        loading: {type: [Boolean, String], default: 'accent'}, 
        editing: Boolean,
    },
    data: () => ({
        mProfile: {},
        hasImage: false,
        statuses: {
            active: 'Actif', old: 'Ancien'
        }
    }),
}
</script>

<style>

</style>