export const GEAR_TYPES = {
    boots: { 
        value: 'boots', 
        name: 'Bottes',
        hasSize: true,
        models: {
            rubber: { value: 'rubber', name: 'Caoutchouc' },
            haixFFGamma: { value: 'haixFFGamma', name: 'Haix Fire Flash Gamma' },
            haixFF20: { value: 'haixFF20', name: 'Haix Fire Flash 2.0' },
        },
    },
    cap: { 
        value: 'cap', 
        name: 'Casquette',
        models: {
            sp: { value: 'sp', name: 'SP' },
            eca: { value: 'eca', name: 'ECA' },
            cdl: { value: 'cdl', name: 'CdL' },
        },
    },
    hood: {
        value: 'hood',
        name: 'Cagoule',
        models: {
            black: { value: 'black', name: 'Noir' }
        }
    },
    doorStop: {
        value: 'doorStop',
        name: 'Cale porte',
        models: {
            v1: { value: 'v1', name: 'Version 1' },
        }
    },
    helmet: { 
        value: 'helmet', 
        name: 'Casque',
        hasVariants: true,
        models: {
            f1: {value: 'f1', name: 'F1'},
            f1xf: {value: 'f1xf', name: 'F1 XF'},
            f2: {value: 'f2', name: 'F2'},
            f2xf: {value: 'f2xf', name: 'F2 XF'},
        },
        variants: {
            white : {value: 'white', name: 'Blanc'},
            lemon : {value: 'lemon', name: 'Lemon'},
            red : {value: 'red', name: 'Rouge'},
        }
    },
    belt: { 
        value: 'belt', 
        name: 'Ceinture',
        models: {
            sp96: { value: 'sp96', name: 'SP 96' },
        },
    },
    patch: {
        value: 'patch',
        name: 'Ecusson',
        models: {
            cdl1: { value: 'cdl1', name: 'CdL v1' },
        },
    },
    fireGloves: { 
        value: 'fireGloves', 
        name: 'Gants feu', 
        hasSize: true,
        models: {
            a: { value: 'a', name: 'Modèle A' }
        },
    },
    workGloves: { 
        value: 'workGloves', 
        name: 'Gants travail', 
        hasSize: true,
        models: {
            leather: { value: 'leather', name: 'Cuir' },
        },
    },
    helmetLamp: {
        value: 'helmetLamp',
        name: 'Lampe casque',
        models: {
            small: { value: 'small', name: 'Petit' },
            long: { value: 'long', name: 'Long' },
        }
    },
    helmetMic: {
        value: 'helmetMic',
        name: 'Osthéophone',
        models: {
            f1: {value: 'f1', name: 'Pour F1'},
        }
    },
    pager: {
        value: 'pager',
        name: 'Pager',
        hasId: true,
        models: {
            sqaudx15: { value: 'sqaudx15', name: 's.QUAD X15' },
        }
    },
    firePants: { 
        value: 'firePants', 
        name: 'Pantalon feu', 
        hasSize: true,
        hasVariants: true,
        models: {
            equinox: { value: 'equinox', name: 'Equinox', },
            phoenix: { value: 'phoenix', name: 'Phoenix', },
            viking: { value: 'viking', name: 'Viking', },
        },
        variants: {
            dps: { value: 'dps', name: 'DPS' },
            dap: { value: 'dap', name: 'DAP' },
        },
    },
    lightPants: {
        value: 'lightPants',
        name: 'Pantalon léger',
        hasSize: true,
        models: {
            sp96: { value: 'sp96', name: 'SP 96' },
        },
    },
    polo: { 
        value: 'polo', 
        name: 'Polo', 
        hasSize: true,
        models: {
            cdl: { value: 'cdl', name: 'CdL' },
        },
    },
    pull: {
        value: 'pull',
        name: 'Pull', 
        hasSize: true,
        models: {
            sp96: { value: 'sp96', name: 'SP 96' },
            buttoned: { value: 'buttoned', name: 'Boutonné' },
        },
    },
    radio: {
        value: 'radio',
        name: 'Radio',
        hasId: true,
        models: {
            tait: { value: 'tait', name: 'TAIT' },
        }
    },
    softshell: { 
        value: 'softshell', 
        name: 'Softshell', 
        hasSize: true,
        models: {
            cdl: { value: 'cdl', name: 'CdL' },
        },
    },
    shirt: { 
        value: 'shirt', 
        name: 'T-Shirt', 
        hasSize: true,
        models: {
            sp96: { value: 'sp96', name: 'SP 96' },
            longSleeved: { value: 'longSleeved', name: 'Manches longues' },
        },
    },
    fireJacket: { 
        value: 'fireJacket', 
        name: 'Veste feu', 
        hasSize: true,
        hasVariants: true,
        models: {
            equinox: { value: 'equinox', name: 'Equinox', },
            phoenix: { value: 'phoenix', name: 'Phoenix', },
            viking: { value: 'viking', name: 'Viking', },
        },
        variants: {
            dps: { value: 'dps', name: 'DPS' },
            dap: { value: 'dap', name: 'DAP' },
        },
    },
    lightJacket: {
        value: 'lightJacket',
        name: 'Veste légère',
        hasSize: true,
        models: {
            sp96: { value: 'sp96', name: 'SP 96' },
        },
    },
}