<template>
    <div>
        <span class="text-h6">Module cylindres</span>
        <v-row>
            <v-col cols="12" sm="4" lg="3">
                <m-claims-checkbox
                    :value="keytubesAdmin"
                    label="Administrateur"
                    hint="Créer, modifier et supprimer des cylindres"
                    @change="$emit('update:keytubesAdmin', $event)"
                />
            </v-col>
            <v-col cols="12" sm="4" lg="3">
                <m-claims-checkbox
                    :value="keytubesMod"
                    label="Modérateur"
                    hint="Modifier des cylindres"
                    @change="$emit('update:keytubesMod', $event)"
                />
            </v-col>
            <v-col cols="12" sm="4" lg="3">
                <m-claims-checkbox
                    :value="keytubesUser"
                    label="Utilisateur"
                    hint="Voir tous les cylindres"
                    @change="$emit('update:keytubesUser', $event)"
                />
            </v-col>
        </v-row>
    </div>
</template>

<script>
import mClaimsCheckbox from './checkbox.vue';
export default {
    name: 'mUserProfileClaimsKeytubes',
    components: {
        mClaimsCheckbox
    },
    props: {
        keytubesAdmin: Boolean,
        keytubesMod: Boolean,
        keytubesUser: Boolean,
    },
}
</script>

<style>

</style>