<template>
    <v-expansion-panels>
        <v-expansion-panel>
            <v-expansion-panel-header>
                Contact
            </v-expansion-panel-header>
            <m-user-profile-panel-contact
                :loading="loading"
                :editing="editing"
                :email0.sync="user.contact.email[0]"
                :email1.sync="user.contact.email[1]"
                :phone-mobile.sync="user.contact.phone.mobile"
                :phone-home.sync="user.contact.phone.home"
                @update="$emit('update:user', user)"
            />
        </v-expansion-panel>

        <v-expansion-panel>
            <v-expansion-panel-header>
                Administratif
            </v-expansion-panel-header>
            <m-user-profile-panel-administrative
                :loading="loading"
                :editing="editing"
                :nip.sync="user.nip"
                :address0.sync="user.address.street[0]"
                :address1.sync="user.address.street[1]"
                :town.sync="user.address.town"
                :zip.sync="user.address.zip"
                :joinDate.sync="user.joinDate"
                :status.sync="user.status"
            />
        </v-expansion-panel>

        <v-expansion-panel>
            <v-expansion-panel-header>
                Opérationnel
            </v-expansion-panel-header>
            <m-user-profile-panel-operational
                :rank.sync="user.rank"
                :section.sync="user.section"
                :station.sync="user.station"
                :scba.sync="user.functions.scba"
                :c1Driver.sync="user.functions.c1Driver"
                :climber.sync="user.functions.climber"
                :cde.sync="user.functions.cde"
                :ci.sync="user.functions.ci"
                :loading="loading"
                :editing="editing"
                @update="$emit('update:user', user)"
            />
        </v-expansion-panel>

        
        <v-expansion-panel>
            <v-expansion-panel-header>
                Formation
            </v-expansion-panel-header>
            <m-user-profile-panel-training
                :dps.sync="user.exGroup.dps"
                :apr.sync="user.exGroup.apr"
                :loading="loading"
                :editing="editing"
                @update="$emit('update:user', user)"
            />
        </v-expansion-panel>

        <v-expansion-panel>
            <v-expansion-panel-header>
                Titre et rôles
            </v-expansion-panel-header>
            <m-user-profile-panel-roles
                :title.sync="user.roles.title"
                :comprom.sync="user.roles.comprom"
                :em.sync="user.roles.em"
                :inframat.sync="user.roles.inframat"
                :scbaTech.sync="user.roles.scbaTech"
                :teachDps.sync="user.roles.teachDps"
                :teachApr.sync="user.roles.teachApr"
                :teachDrive.sync="user.roles.teachDrive"
                :teachRopes.sync="user.roles.teachRopes"
                :teachJsp.sync="user.roles.teachJsp"
                :dapExec.sync="user.roles.dapExec"
                :loading="loading"
                :editing="editing"
                @update="$emit('update:user', user)"
            />
        </v-expansion-panel>
    </v-expansion-panels>
</template>

<script>
import mUserProfilePanelContact from './panels/contact.vue';
import mUserProfilePanelAdministrative from './panels/administrative.vue';
import mUserProfilePanelOperational from './panels/operational.vue';
import mUserProfilePanelTraining from './panels/training.vue';
import mUserProfilePanelRoles from './panels/roles.vue';

export default {
    name: 'mUserProfilePanels',
    components: { 
        mUserProfilePanelContact,
        mUserProfilePanelAdministrative,
        mUserProfilePanelOperational,
        mUserProfilePanelTraining,
        mUserProfilePanelRoles,
    },
    props: {
        user: {type: Object, required: true},
        loading: { type: [Boolean, String], default: 'accent' },
        editing: Boolean,
    },
    data: () => ({
    }),
}
</script>

<style>

</style>