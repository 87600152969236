<template>
    <v-expansion-panel-content>
        <v-row>
            <v-col cols="12" sm="6" md="4">
                <v-text-field
                    label="NIP"
                    :value="nip"
                    :loading="loading"
                    readonly
                    :disabled="editing"
                    type="number"
                    @input="$emit('update:nip', $event)"
                />
            </v-col>
            <v-col cols="12" sm="6" md="4">
                <v-select
                    :value="status"
                    label="Statut"
                    :items="[{ value: 'active', text: 'Actif' }, { value: 'old', text: 'Ancien' }]"
                    :rules="[rules.required]"
                    :loading="loading"
                    :readonly="!editing"
                    @input="$emit('update:status', $event)"
                />
            </v-col>
            <v-col cols="12" sm="6" md="4">
                <v-text-field
                    :value="joinDate"
                    label="Date d'incorporation"
                    type="date"
                    append-icon="mdi-calendar"
                    :rules="[rules.required, rules.date]"
                    :loading="loading"
                    :readonly="!editing"
                    @input="$emit('update:joinDate', $event)"
                />
            </v-col>
            <v-col cols="12" md="6">
                <v-text-field
                    :value="address0"
                    label="Adresse"
                    append-icon="mdi-home-floor-1"
                    :rules="[rules.required]"
                    :loading="loading"
                    :readonly="!editing"
                    @input="$emit('update:address0', $event)"
                />
                <v-text-field
                    :value="address1"
                    label="Complément d'adresse"
                    append-icon="mdi-home-floor-2"
                    :loading="loading"
                    :readonly="!editing"
                    @input="$emit('update:address1', $event)"
                />
            </v-col>
            <v-col cols="12" sm="6">
                <v-text-field
                    :value="town"
                    label="Lieu"
                    append-icon="mdi-home-group" 
                    :loading="loading"
                    :readonly="!editing"
                    @input="$emit('update:town', $event)"
                />
                <v-text-field
                    :value="zip"
                    label="NPA"
                    append-icon="mdi-numeric"
                    :loading="loading"
                    :readonly="!editing"
                    @input="$emit('update:zip', $event)"
                />
            </v-col>
        </v-row>
    </v-expansion-panel-content>
</template>

<script>
import { REQUIRED, DATE } from '@/constants/rules';

export default {
    name: 'mUserProfilePanelAdministrative',
    props: {
        nip: {type: String, default: ''},
        address0: {type: String, default: ''},
        address1: {type: String, default: ''},
        town: {type: String, default: ''},
        zip: {type: String, default: ''},
        joinDate: {type: String, default: ''},
        status: {type: String, default: ''},
        loading: {type: [Boolean, String], default: 'accent'}, 
        editing: Boolean,
    },
    data() { return {
        rules: {
            required: value => REQUIRED(value),
            date: value => DATE(value),
        },
    }},
}
</script>