<template>
    <v-checkbox
        :label="label"
        :input-value="value"
        :hint="hint"
        persistent-hint
        @change="$emit('change', $event)"
    />
</template>

<script>
export default {
    name: 'mUserProfileClaimsCheckbox',
    props: {
        value: { type: Boolean, default: false },
        label: { type: String, default: '' },
        hint: { type: String, default: '' },
    }
}
</script>