<template>
    <v-expansion-panel-content>
        <v-row>
            <v-col cols="12" sm="6">
                <v-text-field
                    :value="email0"
                    label="Adresse mail principale"
                    type="email"
                    append-icon="mdi-email"
                    :rules="[rules.required, rules.email]"
                    :loading="loading"
                    readonly
                    :disabled="editing"
                    @input="$emit('update:email0', $event)"
                />
            </v-col>
            <v-col cols="12" sm="6">
                <v-text-field
                    :value="email1"
                    label="Adresse mail secondaire"
                    type="email"
                    append-icon="mdi-email"
                    :rules="[rules.email]"
                    :loading="loading"
                    :readonly="!editing"
                    @input="$emit('update:email1', $event)"
                />
            </v-col>
            <v-col cols="12" sm="6">
                <v-text-field
                    :value="phoneMobile"
                    label="Téléphone portable"
                    type="tel"
                    hint="Format '+41123456789'"
                    prefix="+"
                    append-icon="mdi-phone"
                    :rules="[rules.required, rules.phone]"
                    :loading="loading"
                    :readonly="!editing"
                    @input="$emit('update:phoneMobile', $event)"
                />
            </v-col>
            <v-col cols="12" sm="6">
                <v-text-field
                    :value="phoneHome"
                    label="Téléphone fixe"
                    type="tel"
                    hint="Format '+41213456789'"
                    prefix="+"
                    append-icon="mdi-phone-classic"
                    :rules="[rules.phone]"
                    :loading="loading"
                    :readonly="!editing"
                    @input="$emit('update:phoneHome', $event)"
                />
            </v-col>
        </v-row>
    </v-expansion-panel-content>
</template>

<script>
import { REQUIRED, TELEPHONE, EMAIL } from '@/constants/rules';

export default {
    name: 'mUserProfilePanelContact',
    props: {
        // profile: {type: Object, required: true},
        email0: {type: String, default: ''},
        email1: {type: String, default: ''},
        phoneMobile: {type: String, default: ''},
        phoneHome: {type: String, default: ''},
        loading: {type: [Boolean, String], default: 'accent'}, 
        editing: Boolean,
    },
    data() { return {
        rules: {
            required: value => REQUIRED(value),
            phone: value => !value || TELEPHONE(value),
            email: value => !value || EMAIL(value),
        },
    }},
}
</script>